































import Vue from 'vue'
import ClickOutside from 'vue-click-outside'

export default Vue.extend({
  name: 'StatusConfirmDropdown',

  props: {
    currentStatus: {
      type: String,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },

  directives: {
    ClickOutside
  },

  data () {
    return {
      isActive: false,
      selectedStatus: '',
      isExpired: false,
      int: null
    }
  },

  computed: {
    availableStatuses () {
      return [
        {
          text: 'Подтвержден',
          value: 'confirmed',
          disabled: ['confirmed', 'assembled', 'dispatched'].includes(this.currentStatus)
        },
        {
          text: 'Отменен',
          value: 'canceled',
          disabled: ['confirmed', 'assembled', 'dispatched'].includes(this.currentStatus)
        },
        {
          text: 'Готов к выдаче',
          value: 'assembled',
          disabled: ['placed', 'assembled', 'dispatched'].includes(this.currentStatus)
        },
        {
          text: 'В пути',
          value: 'dispatched',
          disabled: ['placed', 'confirmed', 'dispatched'].includes(this.currentStatus)
        },
        {
          text: 'Завершен',
          value: 'completed',
          disabled: ['placed', 'confirmed', 'assembled'].includes(this.currentStatus)
        }
      ]
    },

    currentStatusLabel () {
      return this.availableStatuses.find(s => s.value === this.currentStatus)?.text || 'Ждет подтверждения'
    },

    isStatusPlacedExpired () {
      const duration = this.$moment.duration(this.$moment().diff(this.order?.created_at))
      return this.order.status === 'placed' && duration.asMinutes() > 5 && !this.isExpired
    },

    classCombined () {
      return [this.currentStatus, this.isStatusPlacedExpired ? 'is-placed-expired' : null]
    }
  },

  methods: {
    onClickConfirm () {
      this.$emit('on-save-status', {
        status: this.selectedStatus,
        order: this.order
      })
      this.$refs.tippy.tip.hide()
      this.selectedStatus = ''
      this.isActive = false
    }
  },

  mounted () {
    this.int = setInterval(() => {
      const duration = this.$moment.duration(this.$moment().diff(this.order?.created_at))
      if (duration.asMinutes() < 5) {
        this.isExpired = true
        clearInterval(this.int)
      }
    }, 10000)
  },

  beforeDestroy () {
    clearInterval(this.int)
  }
})

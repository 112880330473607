













































































import Vue from 'vue'
import Loading from '@/components/Loading/index.vue'
import StatusConfirmDropdown from './StatusConfirmDropdown.vue'
import { cloneDeep, debounce } from 'lodash'
import { mapState, mapActions } from 'vuex'
import { formatTime } from '@/utils/functions'

export default Vue.extend({
  name: 'CurrentOrders',

  components: {
    Loading,
    StatusConfirmDropdown
  },

  props: {
    ordersItems: {
      type: Array,
      default: () => []
    },
    fetchParams: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      localOrders: [],
      loading: false,
      fields: [
        {
          key: 'operator',
          label: 'Оператор',
          sortable: true
        },
        {
          key: '_courier',
          label: 'Курьер'
        },
        {
          key: 'number',
          label: '№ заказа'
        },
        {
          key: 'created_at',
          label: 'Дата и время заказа',
          sortable: true
        },
        {
          key: '_status',
          label: 'Статус заказа',
          sortable: true
        },
        {
          key: '_client_phone',
          label: 'Телефон клиента'
        },
        {
          key: 'business_profile_login',
          label: 'Логин БП',
          sortable: true
        },
        {
          key: 'business_profile_phone',
          label: 'Телефон бизнеса'
        },
        {
          key: '_products',
          label: 'Состав заказа'
        },
        {
          key: '_deliveryMethod',
          label: 'Способ получения'
        },
        {
          key: '_paymentMethod',
          label: 'Способ оплаты'
        },
        {
          key: 'business_location.address',
          label: 'Локация бизнеса'
        },
        {
          key: 'delivery.address',
          label: 'Адрес доставки'
        },
        {
          key: 'comment',
          label: 'Комментарий'
        },
        {
          key: 'promo_code.code',
          label: 'Промокод'
        },
        {
          key: 'order_price',
          label: 'Стоимость заказа'
        },
        {
          key: 'delivery_price',
          label: 'Стоимость доставки'
        },
        {
          key: 'package_price',
          label: 'Стоимость посуды'
        },
        {
          key: 'discount_amount',
          label: 'Скидка'
        },
        {
          key: 'total_price',
          label: 'Общая стоимость'
        },
        {
          key: '_order_completed_start',
          label: 'Время обработки заказа'
        }
      ],
      interval: null
    }
  },

  computed: {
    ...mapState('orders', ['orderOperators']),

    sortedBy () {
      const replacedValues = {
        operator: 'operator',
        created_at_timestamp: 'created_at',
        status_index: '_status',
        business_profile_id: 'business_profile_login'
      }
      if ((typeof this.fetchParams?.sort_type === 'boolean' && this.fetchParams.sort_type) || (typeof this.fetchParams?.sort_type === 'string' && this.fetchParams.sort_type === 'true')) {
        return replacedValues[this.fetchParams.sort_by]
      }
      return null
    }
  },

  watch: {
    ordersItems: {
      handler (v) {
        this.localOrders = cloneDeep(v)
        clearInterval(this.interval)
        this.startCountups()
      },
      immediate: true
    }
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },

  methods: {
    ...mapActions({
      editOrderAdministration: 'orders/editOrderAdministration',
      getOrdersAdministration: 'orders/getOrdersAdministration'
    }),

    startCountups () {
      this.interval = setInterval(() => {
        this.localOrders.forEach(o => {
          if (o._order_completed_start) {
            const startDate = Date.parse(o.order_completed_start)
            const duration = this.$moment.duration(this.$moment().diff(startDate))
            const days = parseInt(duration.asDays())
            const hours = parseInt(duration.asHours())
            const minutes = parseInt(duration.asMinutes())
            const seconds = parseInt(duration.asSeconds())
            const h = hours - days * 24
            const m = minutes - hours * 60
            const s = seconds - minutes * 60

            o._order_completed_start = `${formatTime(h)}:${formatTime(m)}:${formatTime(s)}`
          }
        })
      }, 1000)
    },

    async onSaveStatus ({ status, order }) {
      await this.editOrderAdministration({
        id: order.id,
        payload: {
          status: status,
          delivery: order.delivery
        }
      })
      this.loading = true
      await this.getOrdersAdministration(this.fetchParams)
      this.localOrders = cloneDeep(this.ordersItems)
      this.loading = false
      clearInterval(this.interval)
      this.startCountups()
    },

    onClickSetOperator (order, operator) {
      order.operator = operator.id
      order._operator = operator
      this.editOrderAdministration({
        id: order.id,
        payload: {
          operator: operator.id,
          delivery: order.delivery
        }
      })
    },

    onInputCourierCell: debounce(function (order) {
      this.editOrderAdministration({
        id: order.id,
        payload: {
          courier: order._courier,
          delivery: order.delivery
        }
      })
    }, 300),

    onInputCommentCell: debounce(function (order) {
      this.editOrderAdministration({
        id: order.id,
        payload: {
          comment: order.comment,
          delivery: order.delivery
        }
      })
    }, 300),

    async sortingChanged ({ sortBy, sortDesc }) {
      const replacedValues = {
        operator: 'operator',
        created_at: 'created_at_timestamp',
        _status: 'status_index',
        business_profile_login: 'business_profile_id'
      }
      const sortValue = replacedValues[sortBy]
      this.$emit('sorting-change', { sortBy: sortValue, sortDesc: !sortDesc })
    },

    onClickOpenBusinessProfile (id) {
      this.$router.push({ name: 'profile-detail', params: { profileId: id } })
    }
  }
})
